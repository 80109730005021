@tailwind base;
@tailwind components;
@tailwind utilities;


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #E8E8E9;
  -webkit-text-fill-color: #171819;
  -webkit-box-shadow: 0 0 0px 1000px #E8E8E9 inset;
  transition: background-color 5000s ease-in-out 0s;
};

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
};

html, body {
  /* height: 100vh; */
  background-color: #171819 !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
};


body::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}
body::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}
body::-webkit-scrollbar-thumb
{
	background-color: #003D70;
}
